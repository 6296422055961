import React from 'react';
import VehicleInfoCollectionContainer from '../VehicleInfoCollectionComponents/VehicleInfoCollectionContainer';
import DefaultCardBody from '../VehicleInfoCollectionComponents/DefaultCardBody';

const VehicleInfoCollectionLoading = () => {
	return (
		<VehicleInfoCollectionContainer>
			<DefaultCardBody>
				<h1 className='card-title ekho-placeholder-wave h1'>
					<span className='ekho-placeholder col-5' />
				</h1>
				<br />
				<br />
				<h1 className='card-title ekho-placeholder-wave h1'>
					<span className='ekho-placeholder col-12' />
				</h1>
				<br />
				<h1 className='card-title ekho-placeholder-wave h1'>
					<span className='ekho-placeholder col-12' />
				</h1>
				<br />
				<div className='p-0 ekho-placeholder-wave' style={{ height: '15vh' }}>
					<span className='ekho-placeholder col-12 h-100' />
				</div>
				<br />
				<h1 className='card-title ekho-placeholder-wave h1'>
					<span className='ekho-placeholder col-12' />
				</h1>
				<br />
				<div className='p-0 ekho-placeholder-wave' style={{ height: '15vh' }}>
					<span className='ekho-placeholder col-12 h-100' />
				</div>
				<br />
				<h1 className='card-title ekho-placeholder-wave h1'>
					<span className='ekho-placeholder col-12' />
				</h1>
				<br />
				<div className='p-0 ekho-placeholder-wave' style={{ height: '15vh' }}>
					<span className='ekho-placeholder col-12 h-100' />
				</div>
			</DefaultCardBody>
		</VehicleInfoCollectionContainer>
	);
};

export default VehicleInfoCollectionLoading;
