import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Progress, StandardHeader, OemProfileContext } from '@ekhodealer/ekho-common/components';
import {
	CHECKOUT_INFO_MAX_WIDTH,
	MOBILE_SIDE_PADDING,
	// WEB_INNER_SIDE_PADDING,
	// WEB_OUTER_SIDE_PADDING,
} from '@ekhodealer/ekho-common';
import { COLORS, COMPLETED_PAGE_SUFFIX } from '../../common/data/constants';
import MobileViewContext from '../../contexts/mobileViewContext';
import SupportModalContext from '../../contexts/supportModalContext';
// eslint-disable-next-line camelcase
import { track_contact_button_clicked } from '../../Utils/analyticsFuncs';

const InfoCollectionProgressBar = ({
	stepNum,
	max,
	pageName,
	setMobileStage,
	// hideHeaderBackButton,
}) => {
	const { toggleSupportModal } = useContext(SupportModalContext);
	const { oemProfileProps } = useContext(OemProfileContext);
	const { mobileView } = useContext(MobileViewContext);
	// If we're on a completed page, max out the progress bar
	if (pageName.endsWith(COMPLETED_PAGE_SUFFIX)) {
		stepNum = max;
	} else if (stepNum >= max) {
		// If we're not on a completed page but progress >= max, bring it down slightly
		const closeToMax = max * 0.95;
		stepNum = closeToMax;
	} else if (stepNum <= 0) {
		// If progress is at 0 or less, make it show some value
		const closeToZero = max * 0.05;
		stepNum = closeToZero;
	}

	useEffect(() => {
		if (mobileView) {
			window.intercomSettings.hide_default_launcher = true;
			window.Intercom('update', window.intercomSettings);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Progress
				value={stepNum}
				min={0}
				max={max}
				height='0.75vh'
				isStriped={false}
				isAnimated={false}
				isAutoColor={false}
				color={COLORS.INFO.name}
				className='thin-progress'
			/>
			{mobileView && (
				<div
					style={{
						overflowY: 'visible',
						padding: `${MOBILE_SIDE_PADDING}rem`,
						display: 'flex',
						justifyContent: 'center',
					}}>
					<div
						style={{
							maxWidth: `${CHECKOUT_INFO_MAX_WIDTH}px`,
							width: '100%',
							paddingLeft: '0',
							paddingRight: '0rem',
						}}>
						<StandardHeader
							hasBackButton
							backButtonText='To Home'
							backButtonOnClick={() => {
								setMobileStage('select');
							}}
							logoUrl=''
							supportButtonOnClick={() => {
								track_contact_button_clicked(oemProfileProps);
								toggleSupportModal(true);
							}}
						/>
					</div>
				</div>
			)}
			{/* {!mobileView && (
				<div
					style={{
						maxWidth: `${CHECKOUT_INFO_MAX_WIDTH}px`,
						width: '100%',
						paddingLeft: '0',
						paddingRight: '0rem',
					}}>
					<div
						style={{
							paddingTop: '0.5rem',
							paddingBottom: '0rem',
							paddingLeft: `${WEB_INNER_SIDE_PADDING}rem`,
							paddingRight: `${WEB_OUTER_SIDE_PADDING}rem`,
						}}>
						<div style={{ borderRadius: '1rem', overflow: 'hidden' }}>
							<Progress
								value={stepNum}
								min={0}
								max={max}
								height='0.75vh'
								isStriped={false}
								isAnimated={false}
								isAutoColor={false}
								color={COLORS.INFO.name}
								className='thin-progress'
							/>
						</div>
					</div>
				</div>
			)} */}
		</>
	);
};

InfoCollectionProgressBar.propTypes = {
	setMobileStage: PropTypes.func.isRequired,
	stepNum: PropTypes.number,
	max: PropTypes.number,
	pageName: PropTypes.string,
	// hideHeaderBackButton: PropTypes.bool,
};

InfoCollectionProgressBar.defaultProps = {
	// hideHeaderBackButton: false,
	stepNum: 0,
	max: 1,
	pageName: '',
};

export default InfoCollectionProgressBar;
