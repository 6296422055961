// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@ekhodealer/ekho-common/components';
import { CHECKOUT_INFO_MAX_WIDTH } from '@ekhodealer/ekho-common';
import MobileViewContext from '../../../contexts/mobileViewContext';

const VehicleSectionSelectContainer = ({ children }) => {
	const { mobileView } = useContext(MobileViewContext);

	return (
		<Card
			shadow='none'
			style={{
				height: '100vh',
				width: '100%',
				borderRadius: '0',
				marginBottom: '0',
				minHeight: 'min-content',
				padding: '0',
				overflowY: 'auto',
			}}>
			<CardBody
				style={{
					width: '100%',
					height: '100%',
					scrollBehavior: mobileView ? 'auto' : 'smooth',
					overflowY: 'auto',
					paddingLeft: mobileView ? '2rem' : '0',
					paddingRight: mobileView ? '2rem' : '0',
					paddingTop: mobileView ? '2rem' : '3rem',
					paddingBottom: mobileView ? '2rem' : '3rem',
					display: 'flex',
					justifyContent: mobileView ? 'center' : 'flex-end',
				}}>
				<div
					style={{
						maxWidth: `${CHECKOUT_INFO_MAX_WIDTH}px`,
						padding: mobileView ? '0' : '0 5rem 0 2rem',
						width: '100%',
					}}>
					{children}
				</div>
			</CardBody>
		</Card>
	);
};

VehicleSectionSelectContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default VehicleSectionSelectContainer;
