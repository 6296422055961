// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import { SUPPORT_TOPICS, EKHO_APPS_ENUM } from '@ekhodealer/ekho-common';
import { fetchDeviceInfo } from '@ekhodealer/ekho-common/utils';
import { COMPLETED_PAGE_SUFFIX } from '../common/data/constants';
import { createLog, sendTransactionalEmail } from '../serverCalls';

// FUNCTIONS TO SEND ANALYTICS EVENTS
export function convert_date_to_unix(date) {
	// Update segment
	const res = date.getTime() / 1000;
	return res;
}

export function createStringFromAddress(address) {
	let addressString = address.addressLineOne ? address.addressLineOne : '';
	if ('addressLineTwo' in address && address.addressLineTwo !== '') {
		addressString += ` ${address.addressLineTwo}`;
	}
	if ('city' in address && address.city !== '') {
		addressString += `, ${address.city}`;
	}
	if ('state' in address && address.state !== '') {
		addressString += `, ${address.state}`;
	}
	if ('zip' in address && address.zip !== '') {
		addressString += ` ${address.zip}`;
	}
	return addressString;
}

export async function track_buyer_provided_pick_up_details(data) {
	// Declaring this function as async so that it works with the confrim details page
	const { currentVehicle, oemProfileProps, registeredUserProps } = data;
	const { pickUpInfo } = currentVehicle;
	const pickUpLocationAddress = createStringFromAddress(pickUpInfo);
	// const { pickUpDate, pickUpTime } = pickUpInfo;

	// Declaring this function as async so that it works with the confrim details page
	const daysSinceDatePurchased = Math.floor(
		(Date.now() - new Date(currentVehicle.datePurchased)) / 86400000,
	);
	const sourceData = fetchDeviceInfo();
	window.analytics.track('buyer_provided_pick_up_details', {
		oemKey: oemProfileProps._oemKey,
		oemEmail: oemProfileProps.emailForUpdates,
		oemName: oemProfileProps.name,
		purchasedVehicleId: currentVehicle.id,
		purchasedVehicleVin: currentVehicle.vin ? currentVehicle.vin : 'not_yet_provided',
		purchasedVehicleName: currentVehicle.name,
		numBoltOnAccessories:
			currentVehicle?.boltOnAccessories?.length || currentVehicle?.accessories?.length,
		daysSinceDatePurchased,
		vehicleStage: currentVehicle.stage,
		orderId: currentVehicle.placedOrderId,
		buyerId: registeredUserProps.id,
		buyerEmail: registeredUserProps.email,
		buyerName: registeredUserProps.fullName,
		pickUpLocationAddress,
		sourceData,
		// pickUpDate,
		// pickUpTime,
	});
	// Return because we call this as an async function and check for a return value in the confirm details page
	return {};
}

export async function track_buyer_clicked_order_status_modal(data) {
	const { currentVehicle, oemProfileProps, registeredUserProps, modalId } = data;

	const daysSinceDatePurchased = Math.floor(
		(Date.now() - new Date(currentVehicle.datePurchased)) / 86400000,
	);
	const sourceData = fetchDeviceInfo();

	window.analytics.track('buyer_clicked_order_status_modal', {
		oemKey: oemProfileProps._oemKey,
		oemEmail: oemProfileProps.emailForUpdates,
		oemName: oemProfileProps.name,
		purchasedVehicleId: currentVehicle.id,
		purchasedVehicleVin: currentVehicle.vin ? currentVehicle.vin : 'not_yet_provided',
		purchasedVehicleName: currentVehicle.name,
		numBoltOnAccessories:
			currentVehicle?.boltOnAccessories?.length || currentVehicle?.accessories?.length,
		daysSinceDatePurchased,
		vehicleStage: currentVehicle.stage,
		orderId: currentVehicle.placedOrderId,
		buyerId: registeredUserProps.id,
		buyerEmail: registeredUserProps.email,
		buyerName: registeredUserProps.fullName,
		modalId,
		sourceData,
	});
	return {};
}

export async function track_buyer_provided_delivery_details(data) {
	// Declaring this function as async so that it works with the confrim details page
	const { currentVehicle, oemProfileProps, registeredUserProps } = data;
	const { deliveryInfo } = currentVehicle;
	const deliveryAddressString = createStringFromAddress(deliveryInfo);

	// Declaring this function as async so that it works with the confrim details page
	const daysSinceDatePurchased = Math.floor(
		(Date.now() - new Date(currentVehicle.datePurchased)) / 86400000,
	);
	const sourceData = fetchDeviceInfo();
	window.analytics.track('buyer_provided_delivery_details', {
		oemKey: oemProfileProps._oemKey,
		oemEmail: oemProfileProps.emailForUpdates,
		oemName: oemProfileProps.name,
		purchasedVehicleId: currentVehicle.id,
		purchasedVehicleVin: currentVehicle.vin ? currentVehicle.vin : 'not_yet_provided',
		purchasedVehicleName: currentVehicle.name,
		numBoltOnAccessories:
			currentVehicle?.boltOnAccessories?.length || currentVehicle?.accessories?.length,
		daysSinceDatePurchased,
		vehicleStage: currentVehicle.stage,
		orderId: currentVehicle.placedOrderId,
		buyerId: registeredUserProps.id,
		buyerEmail: registeredUserProps.email,
		buyerName: registeredUserProps.fullName,
		deliveryAddress: deliveryAddressString,
		deliveryInstructions: deliveryInfo?.deliveryInstructions
			? deliveryInfo.deliveryInstructions
			: 'N/A',
		sourceData,
	});
	// Return because we call this as an async function and check for a return value in the confirm details page
	return {};
}

// export async function track_credit_application_submitted_by_buyer(data) {
// 	// Declaring this function as async so that it works with the confrim details page
// 	const { currentVehicle, oemProfileProps, registeredUserProps } = data;
// 	const daysSinceDatePurchased = Math.floor(
// 		(Date.now() - new Date(currentVehicle.datePurchased)) / 86400000,
// 	);
// 	const { totalPrice } = currentVehicle;
// 	window.analytics.track('credit_application_submitted_by_buyer', {
// 		oemKey: oemProfileProps._oemKey,
// 		purchasedVehicleId: currentVehicle.id,
// 		purchasedVehicleName: currentVehicle.name,
// 		numBoltOnAccessories: currentVehicle.boltOnAccessories.length,
// 		daysSinceDatePurchased,
// 		vin: currentVehicle.vin ? currentVehicle.vin : 'not_yet_provided',
// 		vehicleStage: currentVehicle.stage,
// 		orderId: currentVehicle.placedOrderId,
// 		amountFinanced: totalPrice,
// 		buyerId: registeredUserProps.id,
// 		buyerEmail: registeredUserProps.email,
// 		buyerName: registeredUserProps.fullName,
// 	});
// 	// Return because we call this as an async function and check for a return value in the confirm details page
// 	return {};
// }

export function identify_known_user(registeredUserProps, oemProfileProps) {
	// Update segment
	const sourceData = fetchDeviceInfo();
	window.analytics.identify(`${registeredUserProps.id}`, {
		email: `${registeredUserProps.email}`,
		name: `${registeredUserProps.fullName}`,
		// If registeredUserProps has createdAt, convert it from a Date to a unix timestamp. If it doesn't, set it to empty string
		created_at: registeredUserProps.createdAt
			? convert_date_to_unix(new Date(registeredUserProps.createdAt))
			: '',
		oemKey: `${oemProfileProps._oemKey}`,
		sourceData,
	});
}

export function generic_page_info_collection(
	activePageName,
	prevActivePageName,
	oemProfileProps,
	registeredUserProps,
	purchasedVehicle,
) {
	// In future, we can do some conditional logic - based on the activePageName - to determine what properties to send to segment
	// e.g. when the buyer has picked a loan offer, we can send the loan offer details to segment
	// Get number of days since vehicle was purchased
	const daysSinceDatePurchased = Math.floor(
		(Date.now() - new Date(purchasedVehicle.datePurchased)) / 86400000,
	);
	const sourceData = fetchDeviceInfo();
	window.analytics.page(`bp_${activePageName}`, {
		oemKey: `${oemProfileProps._oemKey}`,
		userFullName: `${registeredUserProps.fullName}`,
		userEmail: `${registeredUserProps.email}`,
		vehicleId: `${purchasedVehicle.id}`,
		vehicleName: `${purchasedVehicle.name}`,
		previousPage: prevActivePageName,
		orderId: `${purchasedVehicle.placedOrderId}`,
		vehicleStage: `${purchasedVehicle.stage}`,
		daysSinceDatePurchased,
		sourceData,
	});
}

export function group_user(oemProfileProps) {
	const sourceData = fetchDeviceInfo();
	window.analytics.group(`${oemProfileProps._oemKey}`, {
		oemId: oemProfileProps.id,
		oemWebsite: oemProfileProps.websiteUrl,
		oemKey: oemProfileProps._oemKey,
		oemBrandColor: oemProfileProps.stylingMap.brandColor,
		oemBrandColorLight: oemProfileProps.stylingMap.brandColorLight,
		oemLogoUrl: oemProfileProps.stylingMap.darkFull,
		oemName: oemProfileProps.name,
		sourceData,
	});
}

export function track_bp_vehicle_viewed(purchasedVehicle, currentPage, oemProfileProps) {
	// Calculate number of days since vehicle was purchased

	const daysSinceDatePurchased = Math.floor(
		(Date.now() - new Date(purchasedVehicle.datePurchased)) / 86400000,
	);
	let numSectionsCompleted = 0;
	let financingComplete = false;
	if (
		currentPage.financing &&
		currentPage.financing.pageName &&
		currentPage.financing.pageName.endsWith(COMPLETED_PAGE_SUFFIX)
	) {
		financingComplete = true;
		numSectionsCompleted += 1;
	}
	// Do the same for titlingAndRegComplete
	let registrationAndTitlingComplete = false;
	if (
		currentPage.registrationAndTitling &&
		currentPage.registrationAndTitling.pageName &&
		currentPage.registrationAndTitling.pageName.endsWith(COMPLETED_PAGE_SUFFIX)
	) {
		registrationAndTitlingComplete = true;
		numSectionsCompleted += 1;
	}
	// Do the same for documentsAndSignaturesComplete
	let documentsAndSignaturesComplete = false;
	if (
		currentPage.documentsAndSignatures &&
		currentPage.documentsAndSignatures.pageName &&
		currentPage.documentsAndSignatures.pageName.endsWith(COMPLETED_PAGE_SUFFIX)
	) {
		documentsAndSignaturesComplete = true;
		numSectionsCompleted += 1;
	}
	// do the same for deliveryComplete
	let deliveryDetailsComplete = false;
	if (
		currentPage.deliveryDetails &&
		currentPage.deliveryDetails.pageName &&
		currentPage.deliveryDetails.pageName.endsWith(COMPLETED_PAGE_SUFFIX)
	) {
		deliveryDetailsComplete = true;
		numSectionsCompleted += 1;
	}
	// Do the same for paymentComplete
	let paymentComplete = false;
	if (
		currentPage.payment &&
		currentPage.payment.pageName &&
		currentPage.payment.pageName.endsWith(COMPLETED_PAGE_SUFFIX)
	) {
		paymentComplete = true;
		numSectionsCompleted += 1;
	}
	const sourceData = fetchDeviceInfo();
	window.analytics.track('bp_vehicle_viewed', {
		oemKey: oemProfileProps._oemKey,
		purchasedVehicleId: purchasedVehicle.id,
		purchasedVehicleName: purchasedVehicle.name,
		daysSinceDatePurchased,
		vehicleStage: purchasedVehicle.stage,
		orderId: purchasedVehicle.placedOrderId,
		financingComplete,
		registrationAndTitlingComplete,
		documentsAndSignaturesComplete,
		deliveryDetailsComplete,
		paymentComplete,
		numSectionsCompleted,
		sourceData,
	});
}

export function page_bp_vehicle_select(purchasedVehicles, oemProfileProps) {
	// Iterate over and find the most recent vehicle purchase based on datePurchased (which is a Date)
	// Add some logic to handle the case where purchasedVehicles is empty
	if (purchasedVehicles.length === 0) {
		const sourceData = fetchDeviceInfo();
		window.analytics.page('bp_vehicle_select', {
			sourceData,
			oemKey: oemProfileProps._oemKey,
			daysSinceMostRecentVehiclePurchase: 0,
			mostRecentPurchasedVehicleId: '',
			mostRecentPurchaseVehicleName: '',
			numberOfPurchasedVehicles: 0,
		});
		return;
	}
	let mostRecentPurchasedVehicle = purchasedVehicles[0];
	purchasedVehicles.slice(1).forEach((vehicle) => {
		if (new Date(vehicle.datePurchased) > new Date(mostRecentPurchasedVehicle.datePurchased)) {
			mostRecentPurchasedVehicle = vehicle;
		}
	});
	// Now calculate how many days ago that was
	const daysSinceMostRecentVehiclePurchase = Math.floor(
		(Date.now() - new Date(mostRecentPurchasedVehicle.datePurchased)) / 86400000,
	);
	const sourceData = fetchDeviceInfo();
	window.analytics.page('bp_vehicle_select', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		daysSinceMostRecentVehiclePurchase,
		mostRecentPurchasedVehicleId: mostRecentPurchasedVehicle.id,
		mostRecentPurchaseVehicleName: mostRecentPurchasedVehicle.name,
		numberOfPurchasedVehicles: purchasedVehicles.length,
	});
}

export function page_bp_dashboard(purchasedVehicles, oemProfileProps) {
	// Iterate over and find the most recent vehicle purchase based on datePurchased (which is a Date)
	let mostRecentPurchasedVehicle = purchasedVehicles[0];
	purchasedVehicles.forEach((vehicle) => {
		if (new Date(vehicle.datePurchased) > new Date(mostRecentPurchasedVehicle.datePurchased)) {
			mostRecentPurchasedVehicle = vehicle;
		}
	});
	// Now calculate how many days ago that was
	const daysSinceMostRecentVehiclePurchase = Math.floor(
		(Date.now() - new Date(mostRecentPurchasedVehicle.datePurchased)) / 86400000,
	);
	const sourceData = fetchDeviceInfo();
	window.analytics.page('bp_dashboard', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		daysSinceMostRecentVehiclePurchase,
		mostRecentPurchasedVehicleId: mostRecentPurchasedVehicle.id,
		mostRecentPurchaseVehicleName: mostRecentPurchasedVehicle.name,
		numberOfPurchasedVehicles: purchasedVehicles.length,
	});
}

export function page_checkout_success(oemProfileProps) {
	const sourceData = fetchDeviceInfo();
	window.analytics.page('checkout_success', {
		oemKey: oemProfileProps._oemKey,
		sourceData,
	});
}

export function track_buyer_began_checkout(
	oemProfileProps,
	vehicles,
	numAccessories,
	totalCartCost,
) {
	const sourceData = fetchDeviceInfo();
	window.analytics.track('buyer_began_checkout', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		numVehicles: vehicles.length,
		numAccessories,
	});
}

export function track_checkout_payment_type_toggled(oemProfileProps, totalCartCost) {
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_payment_type_toggled', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
	});
}

export function track_checkout_buyer_did_press_continue(oemProfileProps, totalCartCost) {
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_buyer_did_press_continue', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
	});
}

export function track_checkout_buyer_pressed_order(oemProfileProps, totalCartCost, miscEcommData) {
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_buyer_pressed_order', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		totalCartCost,
		checkoutUrl: miscEcommData.checkoutUrl ? miscEcommData.checkoutUrl : window.location.href,
	});
}

export function track_checkout_buyer_abandoned_cart(
	vehicles,
	uniqueAccessories,
	paymentIntent,
	oemProfileProps,
	totalCartCost,
	registeredUserProps,
	miscEcommData,
) {
	// track checkout_buyer_abandoned_cart event via segment
	// Create a productImgUrl variable. Set it to the image of the first vehicle in the cart. If there isn't a vehicle, set it to the image of the first accessory.
	let productImgUrl = 'https://i.ibb.co/jvvydFR/Group-170-1.png';
	if (vehicles.length > 0) {
		productImgUrl = vehicles[0].imgUrl;
	} else if (uniqueAccessories.length > 0) {
		productImgUrl = uniqueAccessories[0].imgUrl;
	}
	// Create a listOfItems string which is a comma seperated list of vehicle names and accessory names
	let listOfItems = '';
	vehicles.forEach((vehicle) => {
		listOfItems += `${vehicle.name}, `;
	});
	uniqueAccessories?.forEach((accessory) => {
		const options = accessory?.specs.map((spec) => spec.option).join(', ');
		listOfItems += `${accessory.name}${options ? ` - ${options}` : ''}, `;
	});

	const paymentIntentStatus = paymentIntent ? paymentIntent.status : 'unknown';
	const paymentMethodSelected = paymentIntent ? paymentIntent.payment_method : 'unknown';
	const sourceData = fetchDeviceInfo();
	window.analytics.track('checkout_buyer_abandoned_cart', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		oemWebsite: oemProfileProps.websiteUrl,
		oemName: oemProfileProps.name,
		oemLogoUrl: oemProfileProps.logos.darkFull,
		oemBrandColor: oemProfileProps.stylingMap.brandColor,
		oemBrandColorLight: oemProfileProps.stylingMap.brandColorLight,
		totalCartCost,
		paymentIntentStatus,
		paymentMethodSelected,
		buyerName: registeredUserProps.fullName,
		buyerEmail: registeredUserProps.email,
		productImgUrl,
		listOfItems,
		// Get the current URL
		checkoutUrl: miscEcommData.checkoutUrl ? miscEcommData.checkoutUrl : window.location.href,
	});
	// Uncomment below to throw a warning when user tries to exit the page
	// event.preventDefault();
	// event.returnValue = 'Are you sure you want to abandon your cart?';
}

export async function track_buyer_messaged_oem_support(
	oemProfileProps,
	currentVehicle,
	buyerEmail,
	buyerMessage,
	supportTopicKey,
	buyerFullName,
) {
	const vehicleContext = ` Vehicle: ${currentVehicle?.name || 'N/A'} (vin: ${
		currentVehicle?.vin || currentVehicle ? 'not yet assigned' : 'N/A'
	},
	stage: ${currentVehicle?.stage} || N/A) `;
	const contextForOem = ` ${vehicleContext}, Buyer's registration state: ${
		currentVehicle?.registrationDetails?.registrationState || 'Unknown'
	}.`;

	const buyerFullNameForUrl = buyerFullName.replace(' ', '%20');

	let adminPortalUrl =
		'https://admin.ekhodealer.com/apps/e775940c-8fe6-11ee-978a-37e396552c79/OEM%20Portal';
	if (currentVehicle) {
		adminPortalUrl = `https://admin.ekhodealer.com/apps/e775940c-8fe6-11ee-978a-37e396552c79/OEM%20Portal/Single%20vehicle?=&buyerFullName=${buyerFullNameForUrl}&buyerId=${currentVehicle.buyerId}&placedOrderId=${currentVehicle.placedOrderId}&purchasedVehicleId=${currentVehicle.id}`;
	}
	// not applying the block because we still want the message to be sent to an OEM
	// if (!trackingEnabled()) return;
	const sourceData = fetchDeviceInfo();
	const eventId = 'buyer_messaged_oem_support';

	const messageData = {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		oemName: oemProfileProps.name,
		oemEmail: oemProfileProps.emailForSupport,
		buyerEmail,
		buyerMessage,
		supportTopicKey,
		supportTopic: SUPPORT_TOPICS[supportTopicKey]?.title || 'Unknown',
		ekhoApp: EKHO_APPS_ENUM.BUYER_PORTAL,
		adminPortalUrl,
		buyerCurrentUrl: window.location.href,
		contextForOem,
		buyerFullName,
		toEmail: oemProfileProps.emailForSupport, // send email to OEM
		replyToEmail: buyerEmail, // let OEM reply to buyer1
		from: null,
		identifier: buyerEmail, // associate it with the buyer email
	};
	try {
		await sendTransactionalEmail(messageData, eventId);
		window.analytics.track('buyer_messaged_oem_support', messageData);
	} catch (e) {
		createLog(
			'transactional-email-error',
			`Error sending buyer message to OEM support: ${e}`,
			'ERROR',
		);
		throw e;
	}
}

export function track_contact_button_clicked(oemProfileProps) {
	const sourceData = fetchDeviceInfo();
	window.analytics.track('contact_button_clicked', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		ekhoApp: EKHO_APPS_ENUM.BUYER_PORTAL,
	});
}

export function track_chat_button_clicked(oemProfileProps, supportTopicKey = 'Unknown') {
	const sourceData = fetchDeviceInfo();
	window.analytics.track('chat_button_clicked', {
		sourceData,
		oemKey: oemProfileProps._oemKey,
		ekhoApp: EKHO_APPS_ENUM.CHECKOUT,
		supportTopicKey,
		supportTopic: SUPPORT_TOPICS[supportTopicKey]?.title || 'Unknown',
	});
}
