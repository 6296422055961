// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { PageWrapper, Page } from '@ekhodealer/ekho-common/components';
// import { layoutMenu } from '../../menu';

const VehicleDetailsContainer = ({ children }) => {
	return (
		<PageWrapper title='Your vehicle'>
			<Page container='fluid' style={{ padding: 0, overflow: 'hidden' }}>
				<div className='row h-100' style={{ marginRight: 0, marginLeft: 0 }}>
					{children}
				</div>
			</Page>
		</PageWrapper>
	);
};

VehicleDetailsContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default VehicleDetailsContainer;
