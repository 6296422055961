import React, { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import {
	Navigation,
	ThemeContext,
	OemProfileContext,
	RegisteredUserContext,
	Icon,
	Tooltips,
	useAsideTouch,
	useMinimizeAside,
} from '@ekhodealer/ekho-common/components';
import { dashboardMenu } from '../../menu';
import Brand from '../Brand/Brand';
import { auth } from '../../firebase';
// eslint-disable-next-line camelcase
import { track_contact_button_clicked } from '../../Utils/analyticsFuncs';

const bottomMenuItem = ({ iconName, optionText, onClick, key }) => {
	return (
		<div
			key={key}
			role='presentation'
			className='navigation-item cursor-pointer'
			onClick={() => {
				onClick();
			}}>
			<span className='navigation-link navigation-link-pill'>
				<span className='navigation-link-info'>
					<Icon icon={iconName} className='navigation-icon' />
					<span className='navigation-text'>{optionText}</span>
				</span>
			</span>
		</div>
	);
};

const Aside = ({ toggleSupportModal }) => {
	const { asideStatus } = useContext(ThemeContext);
	useMinimizeAside();

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();
	// const { asideStyle, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	// const { t } = useTranslation(['translation', 'menu']);

	const { oemProfileProps, setOemProfile } = useContext(OemProfileContext);
	const { setRegisteredUser } = useContext(RegisteredUserContext);
	const navigate = useNavigate();

	const bottomUserMenu = [
		{
			iconName: 'ChatBubble',
			optionText: 'Contact support',
			onClick: () => {
				track_contact_button_clicked(oemProfileProps);
				toggleSupportModal(true);
			},
		},
		{
			iconName: 'Logout',
			optionText: 'Logout',
			onClick: () => {
				setRegisteredUser(null);
				setOemProfile(null);
				signOut(auth);
				navigate('/login');
			},
		},
	];

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand />
				</div>
				<div className='aside-body'>
					<Navigation menu={dashboardMenu} id='aside-dashboard' />
					{/* <NavigationLine /> */}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-user-menu-2'>
						<div className='navigation'>
							{bottomUserMenu.map((item, index) =>
								bottomMenuItem({
									iconName: item.iconName,
									optionText: item.optionText,
									onClick: item.onClick,
									key: `${index}-${item.iconName}`,
								}),
							)}
						</div>
					</nav>
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// eslint-disable-next-line no-unused-vars
							onDrag={(_event, _info) => {}}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

Aside.propTypes = {
	toggleSupportModal: PropTypes.func.isRequired,
};

export default Aside;
