// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { CardBody } from '@ekhodealer/ekho-common/components';
import {
	CHECKOUT_INFO_MAX_WIDTH,
	MOBILE_SIDE_PADDING,
	WEB_INNER_SIDE_PADDING,
	WEB_OUTER_SIDE_PADDING,
} from '@ekhodealer/ekho-common';
import MobileViewContext from '../../../contexts/mobileViewContext';

const DefaultCardBody = forwardRef(({ children }, ref) => {
	const { mobileView } = useContext(MobileViewContext);
	return (
		<CardBody
			ref={ref}
			style={{
				scrollBehavior: mobileView ? 'auto' : 'smooth',
				maxHeight: mobileView ? 'none' : '100%',
				overflowY: mobileView ? 'visible' : 'auto',
				paddingLeft: mobileView ? `${MOBILE_SIDE_PADDING}rem` : '0',
				paddingRight: mobileView ? `${MOBILE_SIDE_PADDING}rem` : '0',
				paddingTop: mobileView ? '0.5rem' : '3rem',
				paddingBottom: mobileView ? '0.5rem' : '3rem',
				display: 'flex',
				justifyContent: mobileView ? 'center' : '',
			}}>
			<div
				style={{
					maxWidth: `${CHECKOUT_INFO_MAX_WIDTH}px`,
					width: '100%',
					paddingLeft: mobileView ? '0' : `${WEB_INNER_SIDE_PADDING}rem`,
					paddingRight: mobileView ? '0' : `${WEB_OUTER_SIDE_PADDING}rem`,
				}}>
				{children}
			</div>
		</CardBody>
	);
});

DefaultCardBody.propTypes = {
	children: PropTypes.node.isRequired,
};

DefaultCardBody.defaultProps = {};

export default DefaultCardBody;
