import React, { useContext } from 'react';
import { PageWrapper, Page } from '@ekhodealer/ekho-common/components';
import MobileViewContext from '../../../contexts/mobileViewContext';
import VehicleSectionSelectLoading from './VehicleSectionSelectLoading';
import VehicleInfoCollectionLoading from './VehicleInfoCollectionLoading';
import VehicleDetailsContainer from '../VehicleDetailsContainer';
import { demoPages } from '../../../menu';

const VehicleDetailsLoading = () => {
	const { mobileView } = useContext(MobileViewContext);
	return (
		<PageWrapper title={demoPages.vehicleDetails.text}>
			<Page container='fluid' style={{ padding: 0, overflow: 'hidden' }}>
				<VehicleDetailsContainer>
					{mobileView && (
						<div className='col-12'>
							<VehicleSectionSelectLoading />
						</div>
					)}
					{!mobileView && (
						<>
							<div
								className='col-6 p-0'
								style={{
									height: '100vh',
									position: 'relative',
								}}>
								<VehicleSectionSelectLoading />
							</div>
							<div
								className='col-6 p-0'
								style={{
									height: '100vh',
								}}>
								<VehicleInfoCollectionLoading />
							</div>
						</>
					)}
				</VehicleDetailsContainer>
			</Page>
		</PageWrapper>
	);
};

export default VehicleDetailsLoading;
