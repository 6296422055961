import React, { useContext } from 'react';
import { EkhoDealerFooter } from '@ekhodealer/ekho-common/components';
import VehicleSectionSelectContainer from '../VehicleSectionSelectComponents/VehicleSectionSelectContainer';
import MobileViewContext from '../../../contexts/mobileViewContext';

const VehicleSectionSelectLoading = () => {
	const { mobileView } = useContext(MobileViewContext);
	return (
		<VehicleSectionSelectContainer loading>
			<h1 className='card-title ekho-placeholder-wave h1'>
				<span className='ekho-placeholder col-6' />
			</h1>
			<br />
			<br />
			<div className='p-0 ekho-placeholder-wave' style={{ height: '20vh' }}>
				<span className='ekho-placeholder col-12 h-100' />
			</div>
			<br />
			<h1 className='card-title ekho-placeholder-wave'>
				<span className='ekho-placeholder col-12' />
			</h1>
			<br />
			<div className='p-0 ekho-placeholder-wave' style={{ height: '40vh' }}>
				<span className='ekho-placeholder col-12 h-100' />
			</div>
			<br />
			<br />
			<div
				style={{
					display: 'flex',
					width: '100%',
					justifyContent: 'center',
					paddingBlock: '2rem',
				}}>
				<EkhoDealerFooter linkFourText='' stacked={mobileView} />
			</div>
		</VehicleSectionSelectContainer>
	);
};

export default VehicleSectionSelectLoading;
